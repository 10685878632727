import React, { useEffect, useState } from 'react'
import ComboBox from '../ComboBox'
import { callApi, useCallApi } from '../../modules/utils'

type Props={
    data:any
}
export const ProductItemPurchase:React.FC<Props> = ({data}) => {
  
    const callApi = useCallApi()

   
  return (
    <div>
        
        <ul id="orderitems" className="list-group mt-2">
    <li className="list-group-item active ">
        <div className="row">
            <div className="col-6">Produit</div>
            <div className="col-4 text-center">Quantitè</div>
            <div className="col-2  text-right">Prix</div>
        </div>
    </li>
    {
        data?.attributes?.purchase_items?.data.map((purchase_item:any,index:number)=>{
                return  <li id="288" className="list-group-item">
                <div className="row">
                    <div id="order-item-name" className="col-6">

                    {purchase_item.attributes.product_name}
                    </div>
                    <div className="col-4 d-flex flex-row justify-content-center">
                        <span className="mx-3" id="order-item-quantity">{purchase_item.attributes.quantity}</span>
                    </div>
                    <div className="col-2 text-right"> <span id="order-item-price">{purchase_item.attributes.purchase_price} DH</span></div>
                </div>
            </li>
        })
    }
    
           

</ul>
    </div>
  )
}