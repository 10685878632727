import React, { useState } from 'react'
import { Pagination } from '@mui/material';
import { ListStock } from '../../components/sysStock/ListStock';

export const Stock = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  function handlePageChange(event:any, value:any) {
    
    setCurrentPage(value);

  }
  return (
    <>
            <ListStock states={{currentPage,setCurrentPage,totalItems,setTotalItems,setTotalPages}}/>
            <Pagination count={totalPages} page={currentPage} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/>
    </>
  )
}
