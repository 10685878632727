import React, {useEffect, useState, ChangeEvent} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useCallApi} from '../../modules/utils'

interface FournisseurData {
  id?: string
  name?: string
  contact?: string
  phone?: string
  city?: string
  status?: string
}

export const DetailsFourniosseur = () => {
  const {id} = useParams()

  const [fournisseur, setFournisseur] = useState<FournisseurData | null>(null)
  const callApi = useCallApi()
  const [updatedData, setUpdatedData] = useState<FournisseurData>({})

  const getFournisseur = async () => {
    try {
      const {data} = await callApi({
        route: `api/suppliers/${id}`,
        method: 'GET',
      })
      setFournisseur(data)
      setUpdatedData(data || {})
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getFournisseur()
  }, [])

  const onUpdateCategorie = async () => {
    try {
      const {data} = await callApi({
        route: `api/suppliers/${id}`,
        method: 'PUT',
        body: updatedData,
      })
      toast.success('Modifié avec succès')
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Error updating supplier')
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  return (
    <>
      <tr>
        <td>
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              // onChange={handleProductCheckboxChange(fournisseur.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-45px me-5'></div>
            <div className='d-flex justify-content-start flex-column'>
              <input
                className='d-none'
                type='text'
                value={fournisseur?.id || ''}
                onChange={handleChange}
                name='id'
              />
              <input
                className='form-control'
                type='text'
                name='name'
                value={updatedData?.name || ''}
                onChange={handleChange}
              />
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <input
              className='form-control'
              type='text'
              name='contact'
              value={updatedData?.contact || ''}
              onChange={handleChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
            <input
              className='form-control'
              type='text'
              name='phone'
              value={updatedData?.phone || ''}
              onChange={handleChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <input
              className='form-control'
              type='text'
              name='city'
              value={updatedData?.city || ''}
              onChange={handleChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <select
              className='form-select'
              name='status'
              value={updatedData?.status || ''}
              onChange={handleChange}
            >
              <option value='1'>Active</option>
              <option value='2'>Disabled</option>
            </select>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                onClick={onUpdateCategorie}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
              <Link
                to={`/products/details/${fournisseur?.id || ''}`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
              </Link>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
