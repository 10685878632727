import React, { FC } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
type Props={
    getPurchaseByStatus:(status:string)=>void
}
export const FilterPucrhase:React.FC<Props> = ({getPurchaseByStatus}) => {
  const {setComptabilise,hasPermission} = useAuth()

  return (
    <>
    {
      hasPermission("manage_purchase") &&  hasPermission("manage_purchase_invoiced")?
         <div className='mb-10 me-3'>
            <label className='form-label fs-6 fw-bold'>Achats:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e)=>setComptabilise(e.target.value)}
               
            >
              <option value={""}>Tous</option>
              <option value={0}>Non comptablisées</option>
              <option value={1}>Comptabilisées</option>
              
             
            </select>
          </div>:<></>
    }
  
    <button
        // disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Options Filtre</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Statut:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e)=>getPurchaseByStatus(e.target.value)}
               
            >
              <option value=''>Tous</option>
              <option value='1'>Crée</option>
              <option value='2'>Validée</option>
              <option value='3'>Annulée</option>
             
            </select>
          </div>
         
          {/* end::Input group */}

          {/* begin::Input group */}
          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Last login:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
             
            >
              <option value=''></option>
              <option value='Yesterday'>Yesterday</option>
              <option value='20 mins ago'>20 mins ago</option>
              <option value='5 hours ago'>5 hours ago</option>
              <option value='2 days ago'>2 days ago</option>
            </select>
          </div> */}
          {/* end::Input group */}

          {/* begin::Actions */}
          {/* <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              // disabled={isLoading}
              type='button'
              // onClick={resetData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div> */}
          {/* end::Actions */}
        </div>

     
        {/* end::Content */}
      </div>
    </>

  )
}
