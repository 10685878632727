import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {KTSVG} from '../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import SearchComponent from '../SearchComponent'
import { ItemInventaire } from './ItemInventaire'
import { ModalAddInventaire } from './ModalAddInventaire'

type Props = {
  states: any
  // deleteVille:(id:number)=>void
}
export const ListeInventaire: React.FC<Props> = ({states}) => {
  const [inventories, setInventories] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkInventoryId, setCheckInventoryId] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterInventories, setFilterInventories] = useState<any>([])

  const callApi = useCallApi()


  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedProducts = filterInventories.map((product: any) => ({
      ...product,
      checked,
    }))
    console.log(updatedProducts);
    
    if (checked) {
      const checkedProductIds = updatedProducts.map((product: any) => product?.data?.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedProducts
      .filter((product: any) => product.checked)
      .map((product: any) => product.id)
    setCheckInventoryId(checkedIds)
    setFilterInventories(updatedProducts)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
       
      const updatedProducts = filterInventories.map((product: any) =>
        product.data?.id == productId ? {...product, checked} : product
      )
      console.log(updatedProducts);
      
      setFilterInventories(updatedProducts)
      const checkedIds = updatedProducts
        .filter((product: any) => product.checked)
        .map((product: any) => product.data.id)
      setCheckInventoryId(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteInventory = async (ids: number[]) => {
          if (window.confirm('Voulez-vous vraiment supprimer cet inventaire !')) {
    ids.map(async (id: number) => {
        const filteredposts = inventories.filter((product: any) => product.data.id !== id)
        setFilterInventories(filteredposts)
        setItemsSelected(0)
        toast.success('inventaire supprimé')
        const {data} = await callApi({
          route: 'api/inventories/' + id,
          method: 'DELETE',
        })
     
    }) }
  }

 

  // useEffect(() => {
  //   getProducts()
  //   setFilterInventories(inventories)
  // }, [states.currentPage, states.totalItems])
  const getInventoryByName = async () => {
    try {
      setLoading(true)
      const {data} = await callApi({
        route: `api/inventories?page[size]=10&page[number]=${states.currentPage}&filter[search]=${searchTerm}`,
        method: 'GET',
      })

      
      const initialProducts = data.data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setInventories(initialProducts)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(data.meta.total / data.meta.per_page))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }


  useEffect(() => {
    setFilterInventories(inventories)
  }, [inventories])

  useEffect(() => {
    getInventoryByName()
  }, [searchTerm,states.currentPage])
  return (
    <>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Inventaires
              <SearchComponent
                placeholder={'Recherche inventaire'}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
           
          >
           
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selectionnées
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteInventory(checkInventoryId)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                {' '}
                <button
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  title='actualiser'
                  onClick={()=> getInventoryByName()}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr029.svg' className='svg-icon-2' />
                 
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-primary '
                  data-bs-toggle='modal'
                  data-bs-target='#ModalAddInventory'
                >
                  <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                  Ajouter un inventaire 
                </button>
                <ModalAddInventaire />
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      {/* <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterInventories.every((product: any) => product?.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div> */}
                    </th>
                    <th className='min-w-150px '>Produit</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px text-center'>Quantité</th>
                    <th className='min-w-150px text-center'>Commentaire</th>

                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filterInventories.map((categoreie: any, index: any) => {
                    return (
                      <ItemInventaire
                        // deleteInventory={deleteInventory}
                        key={index}
                        data={categoreie.data}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
