import { Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ListClients } from '../../components/clients/ListClients';
import axios from 'axios';

export default function ListClient() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    function handlePageChange(event:any, value:any) {
      
      setCurrentPage(value);
  
    }
   
    // console.log(JSON.stringify(getModifiedData(), null, 2));
    
    return (
      <>
              <ListClients states={{currentPage,setCurrentPage,totalItems,setTotalItems,setTotalPages}}/>
              <Pagination count={totalPages} page={currentPage} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/>
      </>
    )
}
