import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {KTSVG} from '../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import SearchComponent from '../SearchComponent'
import {ModalAddFournisseur} from './ModalAddFournisseur'
import {ItemFournisseur} from './itemFournisseur'

type Props = {
  states: any
  // deleteVille:(id:number)=>void
}
export const ListCategorie: React.FC<Props> = ({states}) => {
  const [categories, setCategories] = useState<any[]>([])

  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkCategorieId, setCheckCategorieId] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterCategorie, setFilterCategorie] = useState<any>([])
  const [statusFournisseur, setStatusFournisseur] = useState("")

  const callApi = useCallApi()
  const getProducts = async () => {
    try {
      const {data} = await callApi({
        route: 'api/suppliers?page[size]=10&page[number]=' + states.currentPage,
        method: 'GET',
      })

      const initialCategories = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setCategories(initialCategories)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const statusChange = (e: any) => {}
  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedProducts = filterCategorie.map((product: any) => ({
      ...product,
      checked,
    }))
    if (checked) {
      const checkedProductIds = updatedProducts.map((product: any) => product.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedProducts
      .filter((product: any) => product.checked)
      .map((product: any) => product.id)
    setCheckCategorieId(checkedIds)
    setFilterCategorie(updatedProducts)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedProducts = filterCategorie.map((product: any) =>
        product.id === productId ? {...product, checked} : product
      )
      setFilterCategorie(updatedProducts)
      const checkedIds = updatedProducts
        .filter((product: any) => product.checked)
        .map((product: any) => product.id)
      setCheckCategorieId(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteProduct = async (ids: number[]) => {
     if (window.confirm('Voulez-vous vraiment supprimer ce fournisseur !' )) {
      ids.map(async (id: number) => {
     
        const filteredposts = categories.filter((product: any) => product.id !== id)
        setFilterCategorie(filteredposts)
        toast.success('fournisseur supprimé')
        const {data} = await callApi({
          route: 'api/suppliers/' + id,
          method: 'DELETE',
        })   
       })
      }

  }

  const getFournisseurByName = async () => {
    try {
      const {data} = await callApi({
        route: `api/suppliers?page[size]=10&page[number]=1&filter[name]=${searchTerm}&filter[status]=${statusFournisseur}`,
        method: 'GET',
      })

      const initialProducts = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setCategories(initialProducts)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(data.meta.total / data.meta.per_page))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getProducts()
  }, [states.currentPage])
  useEffect(() => {
    setFilterCategorie(categories)
  }, [categories])


  useEffect(() => {
    getFournisseurByName()
  }, [searchTerm, statusFournisseur])
  return (
    <>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Fournisseur
              <SearchComponent
                placeholder={'Recherche Fournisseur'}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <>
              {/* begin::Filter Button */}
              <button
                // disabled={isLoading}
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                Filtre
              </button>
              {/* end::Filter Button */}
              {/* begin::SubMenu */}
              <div
                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                data-kt-menu='true'
              >
                {/* begin::Header */}
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  {/* begin::Input group */}
                  <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>Statut:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={(e: any) => setStatusFournisseur(e.target.value)}
                    >
                      <option value=''>Tous</option>
                      <option value='1'>Activé</option>
                      <option value='0'>Desactivé</option>
                    </select>
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}

                  {/* end::Input group */}

                  {/* begin::Actions */}
                  {/* <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      // disabled={isLoading}
                      // onClick={filterData}
                      className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                    >
                      Reset
                    </button>
                    <button
                      // disabled={isLoading}
                      type='button'
                      // onClick={resetData}
                      className='btn btn-primary fw-bold px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                    >
                      Apply
                    </button>
                  </div> */}
                  {/* end::Actions */}
                </div>
                {/* end::Content */}
              </div>
              {/* end::SubMenu */}
            </>
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selectionnées
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteProduct(checkCategorieId)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                {' '}
                <button
                  type='button'
                  className='btn btn-sm btn-primary '
                  data-bs-toggle='modal'
                  data-bs-target='#ModalAddCategorie'
                >
                  <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                  Nouveau fournisseur
                </button>
                <ModalAddFournisseur />
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterCategorie.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    <th className='min-w-150px '>Nom</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px '>Contact</th>
                    <th className='min-w-150px text-center'>Tel</th>
                    <th className='min-w-150px text-center'>Ville</th>
                    <th className='min-w-150px text-center'>Status</th>

                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filterCategorie.map((categoreie: any, index: any) => {
                    return (
                      <ItemFournisseur
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={categoreie}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
