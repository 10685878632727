import React from 'react'
import { useCallApi } from '../../modules/utils';
import { useAuth } from '../../modules/auth';

type Props = {
    data: any;
  };
export const ItemDetailsSubVente: React.FC<Props> = ({ data })=> {
   
  console.log(data.attributes.sale_items.data);
  
   
    
  return (
    <>
          <div>
      <ul id="orderitems" className="list-group mt-2">
        <li className="list-group-item active ">
          <div className="row">
            <div className="col-8">Produit</div>
            <div className="col-2 text-right">Prix</div>
            <div className="col-2 text-right">Sous-total</div>

          </div>
        </li>
        {data.attributes.sale_items.data.map((sale_item: any, index: number) => {
          return (
            <li key={index} id="288" className="list-group-item">
              <div className="row">
                <div id="order-item-name" className="col-8">
             {sale_item.attributes.quantity+" * "+sale_item.attributes.product_categorie+" - "+ sale_item.attributes.product_name}
                  {/* <ComboBox products={products} idProduct={sale_item.product_id} /> */}
                </div>
                <div className="col-2 text-right">
                  <span id="order-item-price">{sale_item.attributes.product_price} DH</span>
                </div>
               
                <div className="col-2 text-right">
                  <span id="order-item-price">{sale_item.attributes.sub_total} DH</span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
    </>
  )
}
