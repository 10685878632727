import React, { useEffect, useState } from 'react'
import { useCallApi } from '../../modules/utils'
import { ListProductPurhase } from './ListProductPurhase'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { useAuth } from '../../modules/auth'
// import { ToastContainer } from 'react-bootstrap'

export const NewPurchase = () => {
    const [fournisseurs, setFournisseur] = useState<any[]>([])
    const [invoiced, setinvoiced] = useState(false)
    const [returned, setReturned] = useState(false)
    const navigate = useNavigate();
    const {getProducts,products}=useAuth()
    const [dataUpdated, setDataUpdated] = useState<any>({
      id: null,
      date: '',
      warehouse_id: null,
      supplier_id: null,
      discount: 0,
      tax_rate: 0,
      // type:false,
      purchase_items: [
        {
          discount_amount: 0,
          discount_type: '1',
          discount_value: 0,
  
          net_unit_cost: 0,
  
          tax_amount: 0,
          tax_type: '1',
          tax_value: 0,
  
          id: null,
          product_id: null,
          product_cost: 0,
          quantity: 0,
          purchase_price: 0,
          product_price: 0,
        },
      ],
      shipping: 0,
  
      grand_total: 0,
      paid_amount: 0,
      payment_type: 1,
      notes: '',
      status: 1,
    })
  
    const [loading, setLoading] = useState(true)
  
    const callApi = useCallApi()
    
    const saveData =async() => {


        setLoading(true)
              dataUpdated["date"]=new Date()
              dataUpdated["warehouse_id"]=1
              dataUpdated["type"]=invoiced

        try {
            const dat = await callApi({
                    route:returned?`api/purchases-return`:`api/purchases`,
                    method: 'POST',
                    body:dataUpdated ,
                  })
       
          // if(!returned){
          //   const dat = await callApi({
          //     route: `api/purchases`,
          //     method: 'POST',
          //     body:dataUpdated ,
          //   })

          // }else {
          //   const dat = await callApi({
          //     route: `api/purchases-return`,
          //     method: 'POST',
          //     body:dataUpdated ,
          //   })
          // }

          // if(!invoiced && returned==false){
          //   if(!returned){
          //     const dat = await callApi({
          //       route: `api/purchases`,
          //       method: 'POST',
          //       body:dataUpdated ,
          //     })
          //   }else if(invoiced && returned==false){
          //     const dat = await callApi({
          //       route: `api/purchases_invoiced`,
          //       method: 'POST',
          //       body:dataUpdated ,
          //     })
       
  
          //   }else {
          //     const dat = await callApi({
          //       route: `api/purchases-return`,
          //       method: 'POST',
          //       body:dataUpdated ,
          //     })
          //   }
          // }  
           toast.success("ajoute avec succes")
           navigate("/purchases")
           await getProducts()
           localStorage.setItem("products", JSON.stringify(products));
  
           setLoading(false)
         } catch (error:any) {
          toast.error(error.response.data.message)
          setLoading(false)
         }
        }
    const getFournisseur = async () => {
      try {
        const data = await callApi({
          route: 'api/suppliers',
          method: 'GET',
        })
  
        setFournisseur(data.data.data)
        // states.setTotalItems(data.meta.total)
        // states.setTotalPages(Math.ceil(states.totalItems / 100))
        setLoading(false)
      } catch (error) {
        console.error('Error fetching product data:', error)
      }
    }
    useEffect(() => {
     
  
      getFournisseur()
    }, [])

  return (
    <>
    <ToastContainer/>
    <div className='row mb-3 align-items-end' >
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='exampleInputEmail1' className='fw-bold'>
              {' '}
              Identifiant commande :
            </label>
            <input
              name='name'
              type='text'
              className='form-control'
              id='exampleInputEmail1'
              placeholder='Identifiant commande'
              aria-describedby='text'
              value={dataUpdated?.notes}
              onChange={(e) => setDataUpdated({...dataUpdated, notes: e.target.value})}

            />
          </div>
          
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='exampleInputEmail1' className='fw-bold'>
              {' '}
              Nom du Fournisseur :
            </label>

            <select
              name=''
              id=''
              className='form-select'
              onChange={(e) => setDataUpdated({...dataUpdated, supplier_id: e.target.value})}

            >
              <option value="">choisir..</option>
              {fournisseurs &&
                fournisseurs.map((fournisseur, index) => {
                  return (
                    <option
                      key={index}
                      value={fournisseur.id}

                    >
                      {fournisseur.attributes.name}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>
        <div className="col ">
        <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className="col-9 fw-bold">
            <label >Comptabilisée :</label>
            </div>
            <div className="col-3">
              <div className="form-check form-switch" >
                <input className="form-check-input"
                onChange={()=>setinvoiced(!invoiced)}
                 type="checkbox" name="status" role="switch" id="flexSwitchCheckDefault"/>
                </div>
                </div>
                </div>
      </div>
      <div className="col ">
        <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className="col-9 fw-bold">
            <label >Retourne :</label>
            </div>
            <div className="col-3">
              <div className="form-check form-switch">
                <input className="form-check-input"
                onChange={()=>setReturned(!returned)}
                 type="checkbox" name="status" role="switch" id="flexSwitchCheckDefault"/>
                </div>
                </div>
                </div>
      </div>
      </div>
     
      <ListProductPurhase
        update={false}
        saveData={saveData}
        total={dataUpdated.grand_total}
        setDataUpdate={[dataUpdated, setDataUpdated,loading]}
      />
    </>
    
  )
}
