import React, {ChangeEvent, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {createFormData, useCallApi} from '../../modules/utils'
import {number, string} from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import {Loading} from '../Loading'
import {check} from 'prettier'
import {useAuth} from '../../modules/auth'

type Props = {
  data: any

  // deleteVille:(id:number)=>void
}
export const ModalUpdateCategorie: React.FC<Props> = ({data}) => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [image, setImage] = useState<File | any>([])

  const [filess, setFiles] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [chejed, setchejed] = useState(data.attributes.status === '1')
  const [productupdate, setProductUpdate] = useState({
    color: '',
    name: '',
    code: '',

    status: data.attributes.status,
  })
  useEffect(() => {
    setProductUpdate({
      color: data?.attributes.color,
      name: data?.attributes.name,
      code: data?.attributes.code,

      status: data?.attributes.status,
    })
  }, [data.id])
  // const getCategories = async () => {
  //     const {data} = await callApi({
  //       route: 'api/product-categories',
  //       method: 'GET',
  //     })
  //     setCategories(data.data)
  //     // setLoading(false)
  //   }
  const updateProduct = async () => {
    setLoading(true)
    const dataTosend = {
      color: productupdate.color,
      name: productupdate.name,
      status: productupdate.status == 1 ? true : false,
    }

    // const formData = createFormData(dataTosend, image)
    try {
      const dat = await callApi({
        route: `api/product-categories/` + data.id,
        method: 'PUT',
        body: dataTosend,
      })
      toast.success('modifié avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }

    // setLoading(false)
    // Toast.success('modifié')
  }

  return (
    <>
      <div
        className='modal fade'
        id={'ModalUpdateCategorie' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' >
          <div className='modal-content'>
            <div className='modal-header ' >
              <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                Modification de produit: {data.attributes.name}
              </h1>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body' style={{height: '392px', overflowY: 'scroll'}}>
              <form>
                <div className='row mb-4'>
                  <div className='col-10'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Nom :
                      </label>
                      <input
                        type='text'
                        value={productupdate?.name}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setProductUpdate({...productupdate, name: e.target.value})
                        }
                      />
                    </div>
                  </div>
                  <div className='col-2'>
                    <div className='form-group'>
                      <label htmlFor='exampleColorInput' className='fw-bold'>
                        Color
                      </label>
                      <input
                        type='color'
                        className='form-control form-control-color'
                        id='exampleColorInput'
                        value={productupdate?.color}
                        onChange={(e: any) =>
                          setProductUpdate({...productupdate, color: e.target.value})
                        }
                        title='Choose your color'
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='form-group'>
                    <label htmlFor='exampleInputEmail1' className='fw-bold'>
                      Order :
                    </label>
                    <input
                      type='text'
                      value={productupdate?.name}
                      className='form-control'
                      id='exampleInputEmail1'
                      aria-describedby='text'
                      onChange={(e: any) =>
                        setProductUpdate({...productupdate, name: e.target.value})
                      }
                    />
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='form-group'>
                    <label htmlFor='exampleInputEmail1' className='fw-bold form-label'>
                      Magasin :
                    </label>
                    <select name='Magasin' className='form-select' id=''>
                      <option>CHAKIB DRUGSTORE VIP</option>
                    </select>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group d-flex fw-bold'>
                      {' '}
                      <label htmlFor='exampleInputEmail1'>Statut :</label>
                      <div className='form-check form-switch'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {checked} = e.target
                            setchejed(checked)

                            setProductUpdate({...productupdate, status: checked ? 1 : 0})
                          }}
                          checked={chejed}
                          id='flexSwitchCheckDefault'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary'>
                  <Loading />
                </button>
              ) : (
                <button type='button' onClick={updateProduct} className='btn btn-primary'>
                  valider
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
