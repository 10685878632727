import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useCallApi} from '../../modules/utils'
import {ToastContainer, toast} from 'react-toastify'
import SearchComponent from '../SearchComponent'
import {ItemPurchase} from './ItemPurchase'
import {useAuth} from '../../modules/auth'
import {Link} from 'react-router-dom'
import {FilterPucrhase} from './FilterPucrhase'

type Props = {
  states: any
  // deleteVille:(id:number)=>void
}
export const ListPurchase: React.FC<Props> = ({states}) => {
  const [purchases, setPurchases] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedProductIds, setCheckedProductIds] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const {currentUser, comptabilise,hasPermission,setComptabilise,getProducts,products} = useAuth()
  const [filterPurchase, setFilterPurchase] = useState<any>([])
  const {setCurrentPage,currentPage,totalPages,setTotalItems,totalItems,setTotalPages}=useAuth()

  const callApi = useCallApi()
  

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    
    const updatedProducts = filterPurchase.map((product: any) => ({
      ...product,
      checked ,
    }))
    if (checked) {
      const checkedProductIds = updatedProducts.map((product: any) => product.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedProducts
      .filter((product: any) => product.checked)
      .map((product: any) => product.id) 
    setCheckedProductIds(checkedIds)
    setFilterPurchase(updatedProducts)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {

      
      
      const checked = event.target.checked
      const updatedProducts = filterPurchase.map((product: any) =>
        product.id == productId ? {...product, checked} : product
      )

      setFilterPurchase(updatedProducts)
      const checkedIds = updatedProducts
        .filter((product: any) => product.checked)
        .map((product: any) => product.id)
        
      setCheckedProductIds(checkedIds)
      setItemsSelected(checkedIds.length)
    }


  const deleteProduct = async (ids: number[]) => {
    if (window.confirm('Voulez-vous vraiment supprimer les achats selectionnées !')) {

    ids.map(async (id: number) => {
        const filteredposts = filterPurchase.filter((product: any) => product.id !== id)
        setPurchases(filteredposts)
        setItemsSelected(0)

        toast.success('supprimée avec succés')
        let route = `${states.returned ? 'api/purchases-return/'+id : 'api/purchases/' + id}`;

      
        const {data} = await callApi({
          route:route ,
          method: 'DELETE',
        })

        await getProducts()
        localStorage.setItem("products", JSON.stringify(products));

     
    }) }
  }

  const getPurchases = async () => {
    setLoading(true)
    try {
  
      
      const storedPage = localStorage.getItem('currentPage');
      const currentPages = storedPage ? parseInt(storedPage, 10) : currentPage;
  
      const { data } = await callApi({
        route: states.returned
          ? `api/purchases-return?page[size]=10&page[number]=${currentPages}&filter[notes]=${searchTerm}&filter[type]=${comptabilise}`
          : `api/purchases?page[size]=10&page[number]=${currentPages}&filter[notes]=${searchTerm}&filter[type]=${comptabilise}`,
        method: 'GET',
      });
      const initialPurchases = data.data.map((item:any) => ({
        ...item,
        checked: false,
      }));
  
      setPurchases(initialPurchases);
      setTotalItems(data.meta.total);
      setTotalPages(Math.ceil(data.meta.total / data.meta.per_page));
      
      setLoading(false);
      localStorage.removeItem("currentPage");
    } catch (error) {
      console.error('Error fetching product data:', error);
      setLoading(false)
    }
  };
  
  const getPurchaseByStatus = async (status: string) => {
    setLoading(true);
    try {
      let route = comptabilise== 1
        ? `${states.returned ? 'api/purchases-return-invoiced' : 'api/purchases_invoiced'}?page[size]=10&page[number]=${currentPage}&status=${status}&filter[notes]=${searchTerm}`
        : hasPermission("manage_purchase")
          ? `${states.returned ? 'api/purchases-return' : 'api/purchases'}?page[size]=10&page[number]=${currentPage}&status=${status}&filter[notes]=${searchTerm}`
          : `${states.returned ? 'api/purchases-return-invoiced' : 'api/purchases_invoiced'}?page[size]=10&page[number]=${currentPage}&status=${status}&filter[notes]=${searchTerm}`;
  
      const { data } = await callApi({
        route,
        method: 'GET',
      });
  
      const initialPurchases = data.data.map((item:any) => ({
        ...item,
        checked: false,
      }));
  
      setPurchases(initialPurchases);
      setTotalItems(data.meta.total);
      setTotalPages(Math.ceil(data.meta.total / data.meta.per_page));
  
      setLoading(false);
    } catch (error) {
      console.error('Error fetching purchase data:', error);
    }
  };
  
 
 console.log(states);
 
  useEffect(() => {
    setFilterPurchase(purchases);
  }, [purchases])
  useEffect(() => {
 if(comptabilise==""){
  setComptabilise("")
 }
    getPurchases();


  }, [currentPage, comptabilise,states.returned,searchTerm]);
  
 
  


  return (
    <>
    <ToastContainer/>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {states.returned ? "Achats Retournées" :"Achat"}
              <SearchComponent
                placeholder= {states.returned ? "Recherche Achats Retournées" :" Recherche Achat"}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <>
              {/* begin::Filter Button */}
              <FilterPucrhase getPurchaseByStatus={getPurchaseByStatus} />
              {/* end::SubMenu */}
            </>
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selectionnées
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteProduct(checkedProductIds)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                {
                  states.returned ? 
                  <></>:
                <Link
                  to={'/purchase/add'}
                  className='btn btn-sm btn-primary '
                  // data-bs-toggle='modal'
                  // data-bs-target='#modaladdproduct'
                >
                  <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                  Nouvelle commande
                </Link>
                }
               
                {/* <ModalAddProduct /> */}
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>       
                <tr  className='fw-bold text-muted'>   
                  <th className='min-w-150px '></th>   
                   <th className='min-w-150px '>Libellé</th>
                    <th className='min-w-150px text-center'> Fournisseur</th>
                    <th className='min-w-150px text-center'>DateCreation</th>
                    <th className='min-w-100px text-center'>Total</th>

                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filterPurchase.map((product: any, index: any) => {
                    return (
                      <ItemPurchase
                         returned={states.returned}
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={product}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                <tfoot></tfoot>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
