import React, { useEffect, useState } from 'react'
import { Loading } from '../Loading'
import { KTSVG } from '../../../_metronic/helpers'
import { useCallApi } from '../../modules/utils'
import { ToastContainer, toast } from 'react-toastify'
import { AnyObject } from 'yup/lib/types'
type Props = {
    data: any 
    // deleteVille:(id:number)=>void
  }
  
export const ModalProductPromotions: React.FC<Props> = ({data}) =>  {
    const callApi = useCallApi()
    
    
    const [products, setProducts] = useState([]);
    const dettach = async (product_id: any) => {
      try {
        const updatedProducts = products.filter((product:AnyObject) => product.id != product_id);
        setProducts(updatedProducts);
        const { detach } = await callApi({
          route: `api/detach-promo`,
          method: 'POST',
          body: {
            product_id: product_id,
            promotion_id: data.id,
          },
        });
  
        toast.success("promotion detaché");
      

  
        // After detaching, create a new array without the detached product
       
      } catch (error) {
        console.error('Error fetching promotion data:', error);
      }
    };

    const getpromotions = async () => {
      try {
        const {dataa} = await callApi({
          route: `api/promotions/`+data.id,
          method: 'GET',
        })
  
        
        setProducts(dataa.data.products)
       
        // setLoading(false)
      } catch (error) {
        console.error('Error fetching promotion data:', error)
      }
    }


    useEffect(() => {
      setProducts(data.products)
    }, [data])
    
  
  return (
    <>
 <div
        className='modal fade'
        id={'ModalPoductPromo' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' >
          <div className='modal-content'>
            <div className='modal-header ' >
              <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                Listes produit: 
              </h1>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body' style={{overflowY: 'scroll'}}>
              <form>
         
              <div className={`card }`}>
        {/* begin::Header */}
     
    
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                   
                    <th className='min-w-150px '>Produit</th>
                    {/* <th className="min-w-150px">Pays</th> */}

                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {products && products.map((product: any, index: any) => {
                    return (
                        <>
                         <tr>
                      
                      <td>
              <div className="d-flex justify-content-start flex-column">
              <span className="text-dark fw-bold  d-block fs-6">
                        {product.code}
                    </span>
                    <div    className='card-toolbar ml-3 mt-2'>
                    <span>
                    {product.name}
</span>

                   </div>
                  </div>
              </td>
                           
                           
                         
                            <td>
                      
                              <div className="d-flex justify-content-end flex-shrink-0">
                              <div className='d-flex justify-content-end flex-shrink-0'>
                              <button
                              type='button'
                      title='drop'
                      className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm' 
                      onClick={()=>dettach(product.id)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3 '
                      />
                    </button>
                      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Launch demo modal
                      </button>
                      
                      <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              ...
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                              <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                          </div>
                        </div>
                      </div>   */}
                        
                                        
                                          {/* <a
                                            href='#'
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            // onClick={()=>deleteProduct(data.id)}
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/general/gen027.svg'
                                              className='svg-icon-3'
                                            />
                                          </a> */}
                                        </div>
                              </div>
                            </td>
                          </tr>
                      
                      </>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
      
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>

              
               
              
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
             
            </div>
          </div>
        </div>
      </div>
    </>

   
  )
}
