import React, { useEffect, useState } from 'react'
import { useCallApi } from '../../modules/utils';
import { KTSVG } from '../../../_metronic/helpers';

type Props = {
    dataSend: any;
    oldSubSale:any
  };
export const ItemDetailsSubVenteReturn: React.FC<Props> = ({ dataSend,oldSubSale })=> {




  const updatePurchaseItem = (index: number, field: string, value: any) => {
    // Create a copy of the specific item you want to update
    const updatedItem = {
      ...dataSend?.dataUpdated?.sale_items?.data[index],
      attributes: {
        ...dataSend?.dataUpdated?.sale_items?.data[index]?.attributes,
        quantity: value,
      },
    };
  
    // Create a new copy of dataUpdated with the updated item
    const updatedDataUpdated = {
      ...dataSend?.dataUpdated,
      sale_items: {
        ...dataSend?.dataUpdated?.sale_items,
        data: [
          ...dataSend?.dataUpdated?.sale_items?.data.slice(0, index),
          updatedItem,
          ...dataSend?.dataUpdated?.sale_items?.data.slice(index + 1),
        ],
      },
    };
  

    dataSend.setDataUpdated(updatedDataUpdated);
  };
  
  const deleteRow = (id: number) => {
    const updatedData = dataSend?.dataUpdated?.sale_items?.data.filter((item: any) => item.attributes.id !== id);
    const deletedData = oldSubSale?.sale_items?.data.filter((item: any) => item.attributes.id === id);
  
    const updatedDataUpdated = JSON.parse(JSON.stringify(oldSubSale));
  
    updatedDataUpdated.sale_items.data = updatedData;
  
    if (!updatedDataUpdated.sale_items_deleted) {
      updatedDataUpdated.sale_items_deleted = [];
    }
  
    updatedDataUpdated.sale_items_deleted.push(...deletedData);
  
    dataSend.setDataUpdated(updatedDataUpdated);
  };
  

  

  
  
  return (
    <>
         <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                <tr className='fw-bold text-muted'>
      <th className='min-w-50px'>Nom Produit</th>
      <th className='min-w-50px'>Prix</th>
      <th className='min-w-50px'>Quantité</th>
      <th className='min-w-10px text-end'></th>
    </tr>
  </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
      {dataSend?.dataUpdated?.sale_items?.data.map((sale_item: any, index: number) => {
          return (
            <>
            <tr key={index}>
              <td>
                {sale_item.attributes.product_name}
              </td>
              <td>
                {sale_item.attributes.product_price} DH
              </td>

 <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={dataSend?.data?.attributes.id}  />

                      <input className='text-dark fw-bold  d-block fs-6' type="number" max={oldSubSale.sale_items.data[index].attributes.quantity} min={1} value={sale_item.attributes.quantity} 
                      style={{width:"15vh"}}
                      // onKeyPress={handleKeyPress}
                      onChange={(e)=>{   
                        updatePurchaseItem(index,"quantity",e.target.value)                     
                      }}
                      />
                    
                    </div>
                  </div>
                </td>
                        <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                      
                    {/* <button
                      type={'submit'}
                      title='save'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' 
                    //   onClick={()=>updateMarque(id)}
                    >
                      {
                        loading?<div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>:<KTSVG 
                        path='/media/icons/duotune/general/gen005.svg'
                          className='svg-icon-3' />
                      }
                          
                      
                    </button> */}
                    <button
                      title='drop'
                      className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm' 
                      onClick={()=>deleteRow(sale_item.attributes.id)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3 '
                      />
                    </button>

                  </div>  
                </td>
                      </tr>
                     
                      
            </>
                      
                      
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-start"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
    </>
  )
} 
