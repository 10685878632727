import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {ProductDetails} from '../../pages/products/ProductDetails'
import { ModalAddInventaire } from './ModalAddInventaire'
import ComboBox from '../ComboBox'
import { Loading } from '../Loading'
import { useCallApi } from '../../modules/utils'
import { toast } from 'react-toastify'

type Props = {
  data: any
  handleProductCheckboxChange: any
}
export const ItemInventaire: React.FC<Props> = ({
  data,
  handleProductCheckboxChange,
}) => {
    const productsFromStorage = localStorage.getItem("products");
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState<any>()
    const [products, setproducts] = useState<any>([])
    const [dataIn, setDataIn] = useState<any>({
        comment:data?.attributes?.comment,
        quantity:data?.attributes?.quantity
    })
    
    const callApi = useCallApi()

    const getProducts = async () => {
        try {
       
      
          if (productsFromStorage) {
            const parsedProducts = JSON.parse(productsFromStorage);
            
            if (parsedProducts && parsedProducts.length > 0) {
              setLoading(false);
              
              setproducts(parsedProducts);
            }
          } else {
            setproducts([]); // Set products to an empty array or handle it as needed
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching product data:', error);
        }
      };
      
    useEffect(() => {
        getProducts();
    }, [productsFromStorage]); 
    const updateInv= async(id:number)=>{
        const dataTosend = {
           
      
            comment: dataIn.comment,
            quantity: dataIn.quantity
          }
          console.log(dataTosend);
          

          try {
            const dat = await callApi({
              route: `api/inventories/${id}`,
              method: 'PUT',
              body: dataTosend,
            })
          
            toast.success('modifié avec succes')
            setLoading(false)
          } catch (error: any) {
            toast.error(error.response.data.message)
            setLoading(false)
          }
    }
    const updatePurchaseItem = (index: number, field: string, value: any) => {

      // const updatedPurchaseItems = [...setDataUpdate[0].purchase_items];
      // updatedPurchaseItems[index][field] = value;
      
      // setDataUpdate[1]({
      //   ...setDataUpdate[0],
      //   purchase_items: updatedPurchaseItems
      // });
    };
  return (
    <>
      {' '}
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data?.checked}
              onChange={handleProductCheckboxChange(data?.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            {/* / */}

            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data?.id} />
            { data?.attributes?.product_name }<br/>

{ data?.attributes?.product_code }

           </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3'>
              <input type='text' onChange={(e)=>setDataIn({...dataIn,quantity:e.target.value})} value={dataIn.quantity} className='text-dark fw-bold  d-block fs-6'/>

              </div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
          <input type='text' onChange={(e)=>setDataIn({...dataIn,comment:e.target.value})} value={dataIn.comment} className='text-dark fw-bold  d-block fs-6'/>

          </div>
        </td>

        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
            
              {/* <ModalAddInventaire data={data} /> */}
              {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>   */}

              {/* <Link
                to={{
                  pathname: '/products/details/' + data.id,
                  // search: `?product_price=${data.attributes.product_price}&price_purchase_ttc=${data.attributes.price_purchase}`,
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
              </Link> */}

              <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={()=>updateInv(data.id)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen037.svg'
                        className='svg-icon-3'
                      />
                    </a>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
