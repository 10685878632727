import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AnyCnameRecord } from 'dns';

type Props = {
  products?: any;
  idProduct?: number;
  index?:number
  setProduct:(id:any)=>void
  updatePurchaseItem?: CallableFunction
};

// ... (previous code)

const ComboBox: React.FC<Props> = ({ setProduct, products, idProduct, updatePurchaseItem, index }) => {
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>(''); // Track the input value
 
  React.useEffect(() => {
    setSelectedProduct(idProduct);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is "Tab" and if there is a single filtered option
    if (event.key === "Tab" ) {
      const input = inputValue.trim().toLowerCase();
      if (input) {
        const filteredOptions = products.filter(
          (option:any) =>
            option?.attributes?.code.toLowerCase().includes(input) ||
            option?.attributes?.name.toLowerCase().includes(input)
        );

        if (filteredOptions.length === 1) {
          setSelectedProduct(filteredOptions[0]);
          setProduct(filteredOptions[0])

          updatePurchaseItem && updatePurchaseItem(index, "product_id", filteredOptions[0].id);

          
        }
      }
    }
  };
console.log(selectedProduct);

  return (
    <Autocomplete
      className='text-dark fw-bold d-block fs-6'
      disablePortal
      id="combo-box-demo"
      options={products}
      value={selectedProduct}
      getOptionLabel={(option) => option?.attributes?.code + " - " + option?.attributes?.name}
      inputValue={inputValue} // Set the inputValue for Autocomplete
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Update the inputValue
      onChange={(event, newValue) => {
        updatePurchaseItem && updatePurchaseItem(index, "quantity", 0);
        updatePurchaseItem && updatePurchaseItem(index, "sub_total", 0);
        setSelectedProduct(newValue);
        setProduct(newValue)
      }}
      filterOptions={(options, { inputValue }) => {
        const input = inputValue?.trim().toLowerCase();
        return options.filter(
          (option) =>
            option?.attributes?.code.toLowerCase().includes(input) ||
            option?.attributes?.name.toLowerCase().includes(input)
        );
      }}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} onKeyDown={handleKeyDown} />}
    />
  );
};

export default ComboBox;

