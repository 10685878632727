import {Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {Loading} from '../Loading'
import { ToastContainer, toast } from 'react-toastify'
import { ListProductSale } from './ListProductSale'
import { useNavigate } from 'react-router-dom'

export const FormAddSale = () => {
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [dataSaleUpdated, setDataSaleUpdated] = useState<any>({
    id: null,
    date: '',
    warehouse_id: 1,
    customer_id: null,
    discount: 0,
    tax_rate: 0,
    // type:false,
    sale_items: [
      {
        discount_amount: 0,
        discount_type: '1',
        discount_value: 0,

        net_unit_cost: 0,

        tax_amount: 0,
        tax_type: '1',
        tax_value: 0,

        id: null,
        product_id: null,
        product_cost: 0,
        quantity: 0,
        product_price: 0,
      },
    ],
    shipping: 0,

    grand_total: 0,
    paid_amount: 0,
    payment_type: 1,
    payment_status: 1,
    espece:0,
    
   
    notes: '',
    status: "valide",
  })
  const navigate = useNavigate();

  const [clients, setClients] = useState<any>([])

  const getClients=async()=>{
    const data = await callApi({
      route:`api/customers`,
      method: 'GET',
    })
    console.log(data.data);
    
    setClients(data.data.data)
  }


  useEffect(() => {
   getClients()
  
   
  }, [])
  function generateRandomToken(length:number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }
  const saveData =async() => {
    // const date = new Date(); // Obtenez la date actuelle ou utilisez votre propre date

    // const day = String(date.getDate()).padStart(2, '0'); // Obtenez le jour avec un zéro initial si nécessaire
    // const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtenez le mois (les mois commencent à 0, donc ajoutez 1) avec un zéro initial si nécessaire
    // const year = date.getFullYear(); // Obtenez l'année
    
    setLoading(true)
          // dataSaleUpdated["date"]=`${day}/${month}/${year}` 
    //       dataUpdated["warehouse_id"]=1
    
    dataSaleUpdated["token"]=generateRandomToken(6)
 
    try {


      // console.log(dataSaleUpdated);
      // return
        const dat = await callApi({
                route:`api/sale_invoiceds`,
                method: 'POST',
                body:dataSaleUpdated ,
              })
   
      // if(!returned){
      //   const dat = await callApi({
      //     route: `api/purchases`,
      //     method: 'POST',
      //     body:dataUpdated ,
      //   })

      // }else {
      //   const dat = await callApi({
      //     route: `api/purchases-return`,
      //     method: 'POST',
      //     body:dataUpdated ,
      //   })
      // }

      // if(!invoiced && returned==false){
      //   if(!returned){
      //     const dat = await callApi({
      //       route: `api/purchases`,
      //       method: 'POST',
      //       body:dataUpdated ,
      //     })
      //   }else if(invoiced && returned==false){
      //     const dat = await callApi({
      //       route: `api/purchases_invoiced`,
      //       method: 'POST',
      //       body:dataUpdated ,
      //     })
   

      //   }else {
      //     const dat = await callApi({
      //       route: `api/purchases-return`,
      //       method: 'POST',
      //       body:dataUpdated ,
      //     })
      //   }
      // }  
       toast.success("ajoute avec succes")
       navigate("/list_sales_invoiced")
      //  localStorage.setItem("products", JSON.stringify(products));

       setLoading(false)
     } catch (error:any) {
      // toast.error(error.response.data.message)
      setLoading(false)
     }
    }
    console.log(clients);
    
  return (
    <>
      <ToastContainer/>
    <div className='row mb-3 align-items-end' >
     
          
        
        <div className='col-5'>
        <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className=" fw-bold">
            <label >Client  :</label> 
            </div>
            <div className="col">
            <select
              name=''
              id=''
              className='form-select   form-select-solid'
              onChange={(e) => setDataSaleUpdated({...dataSaleUpdated, customer_id: e.target.value})}

            >
              <option value="">choisir..</option>
              {clients &&
                clients.map((client:any, index:number) => {
                  return (
                    <option
                      key={index}
                      value={client.id}

                    >
                      {client.attributes.name}
                    </option>
                  )
                })}
            </select>
            </div>
         
        </div>
        </div>
        <div className="col-5 ">
        <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className=" fw-bold">
            <label >Date de vente  :</label>
            </div>
            <div className="col">
            <input
                            type='date'
                            name='start_date'
                            onChange={(e) => setDataSaleUpdated({...dataSaleUpdated, date: e.target.value})}
                            className='form-control  form-control-solid'                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                </div>
                </div>
      </div>
      
      
      <div className="col-2 ">
        <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className=" fw-bold">
            <label >Remise % :</label>
            </div>
            <div className="col">
            <input
                          onChange={(e) => setDataSaleUpdated({...dataSaleUpdated, discount: e.target.value})}
                           value={dataSaleUpdated.discount}
                            type='text'
                            className='form-control  form-control-solid'                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                </div>
                </div>
      </div>
      </div>
      <div className='row  align-items-end' >
      <ListProductSale
        update={false}
        saveData={saveData}
        total={dataSaleUpdated.grand_total}
        someInfo={{dataSaleUpdated, setDataSaleUpdated,loading}}
      />
      </div>
      
    </>
  )
}
