/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import { Link } from 'react-router-dom'
import { Loading } from '../../../../app/components/Loading'
import { useAuth } from '../../../../app/modules/auth'


type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: any
  descriptionColor?: string
  myDate?: any
  href?: any
   chiffre?:boolean
   cash?:any
   bank?:any
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  myDate,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
   chiffre,
   cash,
   bank,
  href
}) => {
  const {setDate} = useAuth()

  return (
    <Link to={href } onClick={()=>setDate(myDate)} className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>
        <div className='d-flex justify-content-between align-items-center'>
          <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />
          <span className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5 `}>{myDate}</span>
        </div>
        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>
        <div className={`fw-semibold fs-3 text-${descriptionColor}`}>{description!=null || description=="0" ?description:<Loading/>}</div>
       {
        chiffre?
        <div className={`fw-semibold fs-3`}>
        <div className=" d-flex justify-content-around">
                 
                  <div className={` mb-2 mt-5`}>
                  <KTSVG path={"/media/icons/duotune/finance/fin002.svg"} className={`svg-icon-${iconColor} svg-icon-3x ms-n1 d-flex justify-content-center` } />
                  <div className={`fw-semibold fs-3 text-${descriptionColor}`}>{bank===null ? <Loading/>:bank+" MAD" }</div>

                  </div>
      
                 
               
                
                    <div className={` mb-2 mt-5`}>
                  <KTSVG path={"/media/icons/duotune/finance/coins.svg"} className={` svg-icon-${iconColor} svg-icon-3x ms-n1 d-flex justify-content-center`} />
                  <div className={`fw-semibold fs-3 text-${descriptionColor}`}>{cash===null  ? <Loading/>:cash+" MAD" }</div>

                  </div>
                  
        </div>
     
         
      
        </div>:<></>
       }
        

      </div>
    </Link>
  )
}

export {StatisticsWidget5}
