import React, { useState } from 'react'
import { ProductItemPurchase } from './ProductItemPurchase'
import { useAuth } from '../../modules/auth'
import { toast } from 'react-toastify'
import { useCallApi } from '../../modules/utils'

type Props = {
    data: any
  
    // deleteVille:(id:number)=>void
  }
export const ModalDetailsPurchase:React.FC<Props> = ({data}) => {
const {dataUpdated, setDataUpdated} = useAuth()
const [getdata, setSenddata] = useState<any>({})

const callApi = useCallApi()

 const saveData =async() => {
    try {
      // setSenddata(data.attributes)
      getdata["status"]=dataUpdated.status
      
      const dat = await callApi({
        route: `api/purchases/`+data.id,
        method: 'PUT',
        body:getdata ,
      })
       toast.success("modifiée avec succes")
      //  setLoading(false)mo
     } catch (error:any) {
      console.log(error)
      // setLoading(false)
     }
   

  }

  return (
    <div className="modal fade" id={"modaldetailspurchase"+data.id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable modal-lg" >
        <div className="modal-content">
          <div className="modal-header ">
            <h1 className="modal-title fs-5 " id="exampleModalLabel">Commande {data.id}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <div className="row ">
    <div className="col-12 border-left-primary col-sm-12 col-md-12 col-lg-6 mb-2">
        <div className="card border-left-primary shadow h-100" style={{borderLeft:"6px solid #4e73df"}}>
            <div className="card-body">
                <div className="row">
                    <div className="fw-bold text-100 text-primary text-uppercase mb-1 col">N° de Commande:{data.id}</div>
                </div>
                        <div className="mb-0 fw-bold text-gray-700">Fournisseur: <span className='text-uppercase'>{data.attributes.supplier_name}</span> </div>
                <div className="mb-0 fw-bold text-gray-700">Total: {data.attributes.grand_total} DH</div>
                <div className="mb-0 fw-bold text-gray-700">Crée le: {new Date(data.attributes.created_at).toISOString().slice(0, 10)+" "+new Date(data.attributes.created_at).toLocaleTimeString()}   </div>
                <div className="mb-0 fw-bold text-gray-700">Crée par: CDS</div>
            </div>
        </div>
    </div>

    <div className="col-12  border-left-primarycol-sm-12 col-md-12 col-lg-6 mb-2">
        <div className="card border-left-success shadow h-100" style={{borderLeft:"6px solid #1cc88a"}}>
            <div className="card-body">
                <div className="fw-bold  text-xs text-success text-uppercase mb-1">Détail de Commande</div>
                <div className="row">
                    <div className="text-gray-900 col-auto fw-bold">Statut:</div>
                    <div className="col">
                        <select className="form-control  form-control-sm" data-val="true" data-val-required="The OrderStatus field is required."
                                      onChange={(e) => setDataUpdated({...dataUpdated, status: e.target.value})}
                        id="OrderStatus" name="OrderStatus" >
                          <option  value={1} selected={data.attributes.status==1}>Crée</option>
                          <option value={2}selected={data.attributes.status==2}>Validé</option>
                          <option value={3}selected={data.attributes.status==3}>Annulé</option>
                             </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   

         <ProductItemPurchase data={data}/>

  
   
</div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
            <button type="button" className="btn btn-primary" onClick={saveData}>Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
  )
}
