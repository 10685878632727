import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget11,
  ListsWidget3,
  TablesWidget5,
  StatisticsWidget5,
  ListsWidget1,
  ListsWidget6,
  TablesWidget13,
  TablesWidget12,
  TablesWidget11,
  MixedWidget4,
} from '../../../_metronic/partials/widgets'
import {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {Link} from 'react-router-dom'
import {ModalProductVendus} from '../../components/ModalProductVendus'
import {ListsWidget10} from '../../../_metronic/partials/widgets/lists/ListsWidget10'
import { ChartByHour } from '../../components/dashboard/ChartByHour'
import { ChartByMounth } from '../../components/dashboard/ChartByMounth'
import { ChartByYear } from '../../components/dashboard/ChartByYear'
import { useAuth } from '../../modules/auth'

const DashboardPage: React.FC = () => {
  const [todaySalles, setTodaySalles] = useState<any>(null)
  const callApi = useCallApi()
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const dated = `${year}-${month}-${day}`
  const {setDate} = useAuth()

  const [benifices, setBenifices] = useState<any>(null)
  const [date, setdate] = useState(dated)
  useEffect(() => {}, [])
  const getSAlles = async () => {
    setTodaySalles(null)
    setBenifices(null)
    setdate(dated)
    try {
      const {data} = await callApi({
        route: 'api/today-sales-overall-report?date=' + dated,
        method: 'GET',
      })
      const benifices = await callApi({
        route: `api/profit-loss-report?start_date=${dated}&end_date=${dated}`,
        method: 'GET',
      })
      setBenifices(benifices.data.data)
      setTodaySalles(data.data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getSAllesYesterday = async () => {
    setTodaySalles(null)
    setBenifices(null)
    try {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - 1)

      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      const yesterday = `${year}-${month}-${day}`
      setdate(yesterday)

      const {data} = await callApi({
        route: 'api/today-sales-overall-report?date=' + yesterday,
        method: 'GET',
      })

      const benifices = await callApi({
        route: `api/profit-loss-report?start_date=${yesterday}&end_date=${yesterday}`,
        method: 'GET',
      })
      setBenifices(benifices.data.data)
      setTodaySalles(data.data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getSAllesPreciseDay = async (date: any) => {
    setTodaySalles(null)
    setBenifices(null)
    setdate(date)

    try {
      const {data} = await callApi({
        route: 'api/today-sales-overall-report?date=' + date,
        method: 'GET',
      })
      const benifices = await callApi({
        route: `api/profit-loss-report?start_date=${date}&end_date=${date}`,
        method: 'GET',
      })
      setBenifices(benifices.data.data)
      setTodaySalles(data.data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSAlles()
  }, [])

  return (
    <>
      <div className=' bg-white rounded' style={{padding: '1%', marginBottom: '1%'}}>
        <ul className='nav' style={{alignItems: 'center', justifyContent: 'space-around', marginBottom: '1%'}}>
          <li className='nav-item w-25'>
            <a
              onClick={getSAlles}
              className='nav-link btn btn-sm btn-color-muted btn-active active btn-active-light-primary active fw-bold px-4'
              data-bs-toggle='tab'
              href='#kt_table_widget_5_tab_1'
            >
              Aujourd'hui
            </a>
          </li>

          <li className='nav-item w-25'>
            <a
              onClick={getSAllesYesterday}
              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
              data-bs-toggle='tab'
              href='#kt_table_widget_5_tab_2'
            >
              hier
            </a>
          </li>
          <li className='nav-item w-25'>
            <div className='me-3'>
              <input
                className='form-control  form-control-solid nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                type='date'
                onChange={(e) => {
                  getSAllesPreciseDay(e.target.value)
                }}
                // onBlur={handleBlur}
                // value={values.cp_date_debut}
              />
            </div>
          </li>
        </ul>
        <div className='row  g-5 g-xl-10'>
        <div className='col-xl-3 '>
          <StatisticsWidget5
            href={'#'}
            chiffre={true}
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin003.svg'
            color='primary'
            iconColor='white'
            title="CHIFFRE D'AFFAIRES"
            myDate={date}
            cash={todaySalles && todaySalles.today_sales_cash_payment  }
            bank={todaySalles && todaySalles.today_sales_bank_transfer_payment}
            description={todaySalles && todaySalles.today_sales_total_amount+" MAD"  }
            titleColor='white'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3' >
          <StatisticsWidget5
            myDate={date}
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
            color='success'
            iconColor='white'
            title='COMMANDES'
              href='/commande'
            description={todaySalles && todaySalles.today_total_sales}
            titleColor='white'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3' data-bs-toggle='modal' data-bs-target='#modalproductvendus'>
          <StatisticsWidget5
            href={`#`}
            myDate={date}
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/files/fil025.svg'
            color='dark'
            iconColor='gray-100'
            title='PRODUIT VENDUS'
            description={todaySalles && todaySalles.today_sales_total_products_sold}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
        </div>

        <ModalProductVendus data={todaySalles && todaySalles.today_total_products_sold} />
        <div className='col-xl-3'>
          <StatisticsWidget5
            myDate={date}
            href={'#'}
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen050.svg'
            color='warning'
            iconColor='white'
            title='TOTAL DES ANNULÉES'
            description={todaySalles && todaySalles.today_sales_cancelled}
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <StatisticsWidget5
            myDate={date}
            href={'#'}
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/bitcoine.svg'
            color='danger'
            iconColor='white'
            title='Bénifices '
            description={benifices && benifices.Revenue + 'MAD'}
            titleColor='white'
            descriptionColor='white'
          />
        </div>
      </div>
      </div>

      {/* begin::Row */}
    
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        {/* <div className='col-xl-3'>
          <ListsWidget1 className='card-xl-stretch mb-xl-8' />
        </div> */}
        {/* end::Col  catego*/}

        {/* begin::Col */}
        <div className='col-xl-5'>
          <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>  
        <div className={`card mb-3  col-xl-7`}>
        <div className='card-header justify-content-center border-0 pt-5'>
          <div className='card-toolbar '>
            <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4'
                  id='pills-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-home'
                  type='button'
                  role='tab'
                  aria-controls='pills-home'
                  aria-selected='true'
                >
                  Aujourd'hui
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-info  fw-bold px-4'
                  id='pills-profile-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-profile'
                  type='button'
                  role='tab'
                  aria-controls='pills-profile'
                  aria-selected='false'
                >
                  Mois
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger  fw-bold px-4'
                  id='pills-contact-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-contact'
                  type='button'
                  role='tab'
                  aria-controls='pills-contact'
                  aria-selected='false'
                >
                  Année
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* begin::Body */}
        <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
          <div className='tab-content' id='pills-tabContent'>
            <div
              className='tab-pane fade show active'
              id='pills-home'
              role='tabpanel'
              aria-labelledby='pills-home-tab'
            >
              {' '}
              <ChartByHour
                date={date}
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='primary'
                chartHeight='400px'
              />
            </div>
            <div
              className='tab-pane fade'
              id='pills-profile'
              role='tabpanel'
              aria-labelledby='pills-profile-tab'
            >
              {' '}
              <ChartByMounth
                date={date}
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='info'
                chartHeight='400px'
              />
            </div>
            <div
              className='tab-pane fade'
              id='pills-contact'
              role='tabpanel'
              aria-labelledby='pills-contact-tab'
            >
           
              <ChartByYear
                date={date}
                className='card-xxl-stretch-50 mb-5 mb-xl-8'
                chartColor='danger'
                chartHeight='400px'
              />
            </div>
          </div>
        </div>
      </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
    

      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::by caissier */}

        <div className='col-xl-6'>
          <ListsWidget3 className=' card-xxl-stretch mb-xl-3' />
        </div>
        {/* end::Col */}

        {/* begin::Col commecrial*/}
        <div className='col-xl-6'>
          <ListsWidget10 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        {/* end::Col */}
      </div>

      {/* end::Row */}
      {/* begin::Col top product*/}
      <div className=''>
        <ListsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
      {/* end::Col */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
      {/* <MixedWidget4 progress='99%' color='success' title='fff' image='' date='' className=''/> */}
    </>
  )
}

export {DashboardWrapper}
