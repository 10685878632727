import React, {useEffect, useState} from 'react'
import {ListProductPurhase} from '../../components/purchase/ListProductPurhase'
import {useCallApi} from '../../modules/utils'
import {useNavigate, useParams} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useAuth } from '../../modules/auth'

type Props={
  returned:boolean
}
export const DetailsPurchase:React.FC<Props> = ({returned}) => {
  // const [detailsP, setDetailP] = useState<any>()
  const [fournisseurs, setFournisseur] = useState<any[]>([])
  const {comptabilise} = useAuth()
  const navigate=useNavigate()
   const [invoiced, setInvoiced] = useState(false)
  // const [dataUpdated, setDataUpdated] = useState<any>({
  //   id: null,
  //   date: '',
  //   warehouse_id: null,
  //   supplier_id: null,
  //   discount: 0,
  //   tax_rate: 0,
  //   purchase_items: [
  //     {
  //       discount_amount: 0,
  //       discount_type: '1',
  //       discount_value: 0,

  //       net_unit_cost: 0,

  //       tax_amount: 0,
  //       tax_type: '1',
  //       tax_value: 0,

  //       id: null,
  //       product_id: null,
  //       product_cost: 0,
  //       quantity: 0,
  //       purchase_price: 0,
  //       product_price: 0,
  //     },
  //   ],
  //   shipping: 0,

  //   grand_total: 0,
  //   paid_amount: 0,
  //   payment_type: 1,
  //   notes: '',
  //   status: 1,
  // })
  const {dataUpdated, setDataUpdated,hasPermission,getProducts,products} = useAuth()
  const {setCurrentPage,currentPage,totalPages,setTotalItems,totalItems,setTotalPages}=useAuth()

  const [loading, setLoading] = useState(true)
  const {id} = useParams()
  const callApi = useCallApi()
  const getPurchases = async () => {
    try {
        const data = await callApi({
          route: returned? 'api/purchases-return/' + id:'api/purchases/' + id,
          method: 'GET',
        })
       
        setDataUpdated({
          ...dataUpdated,
          id: data.data.data.id,
          date: data.data.data.attributes.date,
          supplier_id: data.data.data.attributes.supplier_id,
          warehouse_id: data.data.data.attributes.warehouse_id,
          discount: data.data.data.attributes.discount,
          tax_rate: data.data.data.attributes.tax_rate,
          purchase_items: data.data.data.attributes.purchase_items.data,
          shipping: data.data.data.attributes.shipping,
          notes: data.data.data.attributes.notes,
          grand_total: data.data.data.attributes.grand_total,
          status: data.data.data.attributes.status,
          type:data.data.data.attributes.type
        })
        // console.log ("data",data.data.data)
        setInvoiced(data.data.data.attributes.type==1?true:false)
      
      
      // setDetailP(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getFournisseur = async () => {
    try {
      const data = await callApi({
        route: 'api/suppliers',
        method: 'GET',
      })

      setFournisseur(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  
 
  useEffect(() => {
    setDataUpdated({...dataUpdated, type:invoiced})
  }, [invoiced])
   useEffect(() => {
    getPurchases()
    
    getFournisseur()
  }, [])
  
  // console.log(detailsP);
  const saveData =async() => {
    // setLoading(true)
    
    const transfData=  {
      id: dataUpdated.id,
      date: dataUpdated.date,
      warehouse_id: dataUpdated.warehouse_id,
      supplier_id: dataUpdated.supplier_id,
      discount: dataUpdated.discount,
      tax_rate: dataUpdated.tax_rate,
     
      purchase_items: dataUpdated.purchase_items.map((item:any) => ({
        id:item?.data?.id==undefined ? null:item.data.id,
        product_cost:0,
        discount_amount: 0,
        discount_type: '1',
        discount_value: 0,
       
        net_unit_cost: 0,
        tax_amount: 0,
        tax_type: '1',
        purchase_price: item.purchase_price ? item.purchase_price:item.data.attributes.purchase_price,
        product_price: item.product_price ? item.product_price:item.data.attributes.product_price,

        tax_value: 0,
        product_id:item.product_id? item.product_id:item.data.attributes.product_id,
        sub_total: item.sub_total>=0 ? item.sub_total:item.data.attributes.sub_total,
        quantity: item.quantity>=0? item.quantity:item.data.attributes.quantity,
      })),
      shipping: dataUpdated.shipping,
      grand_total: 0,
      paid_amount: dataUpdated.paid_amount,
      payment_type: dataUpdated.payment_type,
      notes: dataUpdated.notes,
      status: dataUpdated.status,
      type:dataUpdated.type==true?1:0
    };
    transfData.grand_total = transfData.purchase_items.reduce(
      (total:any, item:any) => total + item.sub_total,
      0
    );
  console.log(transfData);
    try {

      const dat = await callApi({
        route: returned?`api/purchases-return/`+id:`api/purchases/`+id,
        method: 'PUT',
        body:transfData ,
      })


       toast.success("modifier avec succes")
       setLoading(false)
       getPurchases()
       !returned? navigate("/purchases"):navigate("/purchases-returned")
       await getProducts()
       localStorage.setItem("products", JSON.stringify(products));


     } catch (error:any) {
      
      toast.error(error.response.data.message)
      setLoading(false)
     }
  }

  console.log(dataUpdated.type);
  
  return (
    <>
    <ToastContainer/>
    <div className="d-flex justify-content-end">
    <button onClick={() => {
    localStorage.setItem('currentPage', currentPage.toString());
    navigate(-1);
  }}  className="btn btn-sm btn-primary shadow-sm mr-1">
        <i className="fas fa-arrow-circle-left"></i>
        <span className="d-none d-sm-inline-block font-weight-bold">Retoure</span>
    </button>
    </div>
   
      <div className='row mb-3'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='exampleInputEmail1' className='fw-bold'>
              {' '}
              Identifiant commande :
            </label>
            <input
              name='name'
              type='text'
              value={dataUpdated?.notes}
              className='form-control'
              id='exampleInputEmail1'
              aria-describedby='text'
              onChange={(e) => setDataUpdated({...dataUpdated, notes: e.target.value})}

            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='exampleInputEmail1' className='fw-bold'>
              {' '}
              Nom du Fournisseur :
            </label>

            <select
              name=''
              id=''
              className='form-select'
              onChange={(e) => setDataUpdated({...dataUpdated, supplier_id: e.target.value})}
            >
              {fournisseurs &&
                fournisseurs.map((fournisseur, index) => {
                  return (
                    <option
                      key={index}
                      value={fournisseur.id}
                      selected={fournisseur.id == dataUpdated.supplier_id}
                    >
                      {fournisseur.attributes.name}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>
        <div className="col ">
          <div className="form-group">
          <label htmlFor='exampleInputEmail1' className='fw-bold'>
              {' '}
            
            </label>

  <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className="col-9 fw-bold">
            <label >Comptabilisée :</label>
            </div>
            <div className="col-3">
              <div className="form-check form-switch" >
                <input className="form-check-input"
                              // onChange={(e) => setDataUpdated({...dataUpdated, type: invoiced})}

                onChange={()=>setInvoiced(!invoiced)}
                // disabled={invoiced}
                checked={invoiced}
                 type="checkbox" name="status" role="switch" id="flexSwitchCheckDefault"/>
                </div>
                </div>
                </div>

          
          </div>
       
      </div>
      </div>
      

      <ListProductPurhase
        saveData={saveData}
        total={dataUpdated?.grand_total}
        setDataUpdate={[dataUpdated, setDataUpdated,loading]}
        update={true}
      />
    </>
  )
}
