import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {ProductDetails} from '../../pages/products/ProductDetails'
import {ModalUpdateFournisseur} from './ModalUpdateFournisseur'
import {toast} from 'react-toastify'
import {log} from 'console'
import {useCallApi} from '../../modules/utils'

type Props = {
  data: any
  handleProductCheckboxChange: any
  deleteProduct?: (id: number) => void
}

export const ItemFournisseur: React.FC<Props> = ({
  data,
  handleProductCheckboxChange,
  deleteProduct,
}) => {
  const callApi = useCallApi()
  const [updatedData, setUpdatedData] = useState<any>()

  const onUpdateCategorie = async (id: number) => {
    const dataToSend = {
      address: updatedData?.address,
      country: 'maroic',
      email: updatedData?.email,
      name: updatedData?.name,
      phone: updatedData?.phone,
      city: updatedData?.city,
      contact: updatedData?.contact,
      status: updatedData?.status,
    }

    try {
      const dat = await callApi({
        route: `api/suppliers/` + id,
        method: 'PUT',
        body: dataToSend,
      })
      toast.success('modifié avec succes')
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }
  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target
    setUpdatedData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setUpdatedData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
  }
  useEffect(() => {
    setUpdatedData(data.attributes)
  }, [data])


  return (
    <>
      {' '}
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handleProductCheckboxChange(data.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-45px me-5'></div>
            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data.id} onChange={handleInputChange} />
              <input
                className='form-control'
                type='text'
                name='name'
                value={updatedData?.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <input
              className='form-control'
              type='text'
              name='contact'
              value={updatedData?.name}
              onChange={handleInputChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
            <input
              className='form-control'
              type='text'
              name='phone'
              value={updatedData?.phone}
              onChange={handleInputChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <input
              className='form-control'
              type='text'
              name='city'
              value={updatedData?.city}
              onChange={handleInputChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <select
              className='form-select'
              name='status'
              value={updatedData?.status}
              onChange={handleChangeSelect}
            >
              <option value='1' selected={updatedData?.status == 1 ? true : false}>
                active
              </option>
              <option
                value='0'
                selected={updatedData?.status == 0 || updatedData?.status == null ? true : false}
              >
                desactiver
              </option>
            </select>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                onClick={() => onUpdateCategorie(data.id)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
              <Link
                to={{
                  pathname: '/fournisseur/details/' + data.id,
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
              </Link>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
